var postFormDataAsJson = async ({
    url,
    data
}) => {
    
    const jsonString = JSON.stringify(data);
    // console.log(jsonString);

    const fetchOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: jsonString,
    };


    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
    }

    return response.status;
}

var handleFormSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const url = 'https://intelligent-cass-llmad-dsp-19eea695.koyeb.app/form';
    // const url = 'http://127.0.0.1:8000/form';

    const contactSection = document.getElementById('contact-section');
    const contactSending = document.getElementById('contact-sending');
    const contactSent = document.getElementById('contact-sent');
    const contactError = document.getElementById('contact-error');

    contactSection.classList.add('d-none');
    contactSending.classList.remove('d-none');

    try {
        const formData = new FormData(form);
        const data = Object.fromEntries(formData.entries());
        
        const responseStatus = await postFormDataAsJson({
            url,
            data
        });

        contactSent.classList.remove('d-none');
        contactSending.classList.add('d-none');

        window.gtag('event', 'form_sent', {responseStatus: responseStatus});

    } catch (error) {

        contactError.classList.remove('d-none');
        contactSending.classList.add('d-none');
    }
}


const contactForm = document.getElementById('contact-form');

contactForm.addEventListener("submit", handleFormSubmit);
